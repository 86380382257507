import { proxy } from 'valtio'

const state = proxy({
  intro: true,
  colors: ['#ccc', '#EFBD4E', '#80C670', '#b05fae', '#EF674E', '#353934'],
  decals: ['m-pink-gradient-offblack-background', 'm-pink-gradient-white-background', 'm-white-pink-gradient-background'],
  color: '#b05fae',
  decal: 'm-pink-gradient-white-background'
})

export { state }
