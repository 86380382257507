import { useSnapshot } from "valtio";
import { state } from "./store";

export function Header() {
  const snap = useSnapshot(state);
  return (
    <>
      <div className="flex space-x-2">
        <button style={{
            color: snap.color,
        }} className="bg-white text-black border  px-4 py-2 rounded z-10">Home</button>
        <button 
            style={{ color: snap.color }} 
            className="bg-white text-black border px-4 py-2 rounded z-10"
            onClick={() => window.location.href = 'mailto:ishan@mirelle.in'}
        >
            Contact
        </button>
      </div>
    </>
  );
}
