import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './styles.css';
import { App as Canvas } from './Canvas';
import { Overlay } from './Overlay';
import Terms from './terms';
import Privacy from './privacy';

createRoot(document.getElementById('root')).render(
  <Router>
    <Routes>
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/" element={
        <>
          <Canvas />
          <Overlay />
          <footer>
            <div className="footer-container" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <p>&copy; 2024 Shipdeck Advisors Private Limited. All rights reserved.</p>
                <p>Address: 97, Pocket - F, Sheikh Sarai Phase-1, Green View Apartments</p>
                <p>Contact: ishan@mirelle.in</p>
              </div>
              <div className="footer-links" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <p><a href="/terms">Terms of Service</a></p>
                <p><a href="/privacy">Privacy Policy</a></p>
              </div>
            </div>
          </footer>
        </>
      } />
    </Routes>
  </Router>
);